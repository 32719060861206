<template>
    <Head v-if="store">
        <title>{{ siteTitle }} | {{ pageTitle }} | {{ appTitle }}</title>
        <link rel="canonical" :href="window.location.href"/>
        <meta v-if="pageDescription" name="description" :content="pageDescription">
        <meta property="og:title" :content="pageTitle">
        <meta property="og:type" content="website"/>
        <meta property="og:url" :content="window.location.href">
        <meta property="og:site_name" :content="siteTitle">
        <meta v-if="pageDescription" property="og:description" :content="pageDescription">
        <meta v-if="store.logo" property="og:image" :content="store.logo.url">
        <meta v-if="store.logo" name="twitter:card" :content="store.logo.url">
        <meta v-if="store.logo" name="twitter:image:alt" :content="siteTitle">
    </Head>
    <div v-if="store" data-aos="fade-in" style="transform:none">
        <Suspense>
            <router-view/>
        </Suspense>
    </div>
</template>

<script>
    import PageMeta from '@/mixins/pageMeta'
    import dataStore from '@/store/data'
    import AOS from 'aos'
    import axios from 'axios'
    import {Head} from '@vueuse/head'

    export default {
        name: 'App',
        inject: ['dataStore', 'errorHandler'],
        mixins: [PageMeta],
        props: {
            sdk: null
        },
        components: {Head},
        computed: {
            store() {
                return this.dataStore.getStore()
            },
            appTitle() {
                if (import.meta.env.VITE_APP_TITLE) {
                    return import.meta.env.VITE_APP_TITLE
                }

                return 'Sugarcoat'
            }
        },
        methods: {
            setupStoreTheme: function(){
                const store = this.dataStore.getStore()
                const defaults = {
                    font_body: "'Inter', sans-serif",
                    font_heading: "'Epilogue', sans-serif",
                    background_color: "#768dce",
                    text_color: "#FFFFFF",
                    primary: "#768dce",
                    secondary: "#f08183"
                }

                let cssString = ":root { " +
                    "--theme-body-font-family: {{font_body}}; " +
                    "--theme-heading-font-family: {{font_heading}}; " +
                    "--theme-body-background-colour: {{background_color}}; " +
                    "--theme-body-text-colour: {{text_color}};" +
                    "--theme-heading-text-colour: {{text_color}};" +
                    "--theme-colour-primary: {{primary}};" +
                    "--theme-colour-secondary: {{secondary}};"
                    "}"

                let styles = document.createElement('style')

                if(store.theme && 'fonts' in store.theme) {
                    let font = document.createElement('link')
                    font.href = store.theme.fonts.cdn
                    font.rel = 'stylesheet'
                    document.head.appendChild(font)
                    
                    for (const [key, value] of Object.entries(store.theme.fonts)) {
                        cssString = cssString.replace(
                            new RegExp('{{font_' + key + '}}', 'g'),
                            value
                        )
                    }
                }

                if(store.theme && 'colors' in store.theme) {
                    for (const [key, value] of Object.entries(store.theme.colors)) {
                        cssString = cssString.replace(
                            new RegExp('{{' + key + '}}', 'g'),
                            value
                        )
                    }
                }

                for (const [key, value] of Object.entries(defaults)) {
                    cssString = cssString.replace(
                        new RegExp('{{' + key + '}}', 'g'),
                        value
                    )
                }

                if(import.meta.env.VITE_APP_THEME){
                    document.documentElement.classList.add(import.meta.env.VITE_APP_THEME)
                }

                if(import.meta.env.VITE_APP_FAVICON){
                    document.getElementById('fav-icon').setAttribute('href', import.meta.env.VITE_APP_FAVICON)
                }

                styles.innerText = cssString
                document.head.appendChild(styles)
            }
        },
        created() {
            AOS.init({
                duration: 400,
                easing: 'ease-in-out-sine',
                once: true,
                offset: 60
            })

            const urlParams = new URLSearchParams(window.location.search)
            const partnerReference = urlParams.get('partner_reference')

            if (partnerReference) {
                this.dataStore.setPartnerReference(partnerReference)
            }

            this.setupStoreTheme()
        }
    }
</script>